import { createRouter, createWebHistory } from "vue-router";

const routes = [
  // Website
  {
    path: "/",
    name: "website-layout",
    component: () => import("@/layouts/WebsiteLayout.vue"),
    children: [
      {
        path: "/",
        name: "home-view",
        component: () => import("@/views/Website/HomeView.vue")
      },
      {
        path: "/about",
        name: "about-view",
        component: () => import("@/views/Website/AboutView.vue")
      }
    ]
  },
  // Common
  {
    path: "/not-authorised",
    name: "not-authorised",
    component: () => import("@/views/NotAuthorised.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "page-not-found",
    component: () => import("@/views/PageNotFound.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: '/',
  linkActiveClass: "active",
  routes
});

export default app => {
  app.router = router;
  app.use(router);
};
