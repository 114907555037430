<template>
  <router-view />
</template>

<style lang="scss">
active {
  @apply text-white;
}
</style>

